import Cookies from 'js-cookie';
import getUserInformations from './get_user_informations.js';
import getBasketDatas from './get_basket_datas.js';
import getUserEligibleCagnotte from './get_user_eligible_cagnotte.js';
import { getComparatorTrackingStatus, getLiveComparatorStatus } from './comparator.js';
import { getCustomerAccountStatus, getUserTrackingDatas } from './user_informations.js';
import { getUserLocationTrackingStatus, getZipcodeUserTracking } from './geoloc.js';
import { dynamicallyLoadScript } from './utils.js';
// eslint-disable-next-line import/no-cycle
import { loadOmniture } from './omniture.js';

let datalayer = {};
const userID = Cookies.get('DARTYIDS');

async function listenClickOnBasketAddArea() {
    document.addEventListener('click', (e) => {
        const basketAddAreaValue = e.target.closest('[data-basket-add-area]')?.dataset.basketAddArea;
        sessionStorage.setItem('TC_BASKET_ADD_AREA', basketAddAreaValue);
    });
}

async function getUserData() {
    const basketDatas = await getBasketDatas();
    if (userID !== undefined) {
        const userDatas = await getUserInformations();
        const data = $.extend({}, basketDatas, userDatas);
        return getUserTrackingDatas(data);
    }
    return getUserTrackingDatas(basketDatas);
}

function listenBasketAddArea() {
    let readBasketAddArea = sessionStorage.getItem('TC_BASKET_ADD_AREA');
    listenClickOnBasketAddArea();
    if (readBasketAddArea != null) {
        sessionStorage.removeItem('TC_BASKET_ADD_AREA');
    } else {
        readBasketAddArea = '';
    }
    return readBasketAddArea;
}

function getRefTemplateData(currentData) {
    const data = currentData;
    if (data.env_template.length > 0) {
        data.env_reftemplate = sessionStorage.getItem('env_reftemplate');
        sessionStorage.setItem('env_reftemplate', data.env_template);
    }
}

function getProductPosition(currentData) {
    const data = currentData;
    const position = localStorage.getItem('track_product_position') || null;
    if (position) {
        data.search_position = position;
        localStorage.removeItem('track_product_position');
    }
}

export function updateDatalayer(key, value) {
    if (arguments.length === 2 && typeof key === 'string') {
        datalayer[key] = value;
    }
    document.dispatchEvent(new CustomEvent('datalayer_updated.tagcommander', { detail: datalayer }));
}

export default async function tagCommanderInit() {
    window.ANALYTICS = window.ANALYTICS || {};
    const tagCoUrl = document.querySelector('meta[name="tc_conteneur"]').getAttribute('content');
    let mainContainer;
    let retrievePageData;
    let tagCoDatas = {};

    class TcContainer {
        constructor(containerId, containerUrl) {
            this.id = containerId;
            this.url = containerUrl;
        }

        load() {
            dynamicallyLoadScript(this.url);
        }
    }

    function Parser(dataSelector) {
        return function () {
            const elements = document.querySelectorAll(`[${dataSelector}]`);
            const data = {};

            if (elements.length === 0) {
                return false;
            }
            elements.forEach((element) => {
                data[element.name] = element.content;
            });

            return data;
        };
    }

    function initComparatorBtn() {
        if (document.querySelector('.actions .comparator')) {
            const comparatorBtn = document.querySelector('.actions .comparator');
            comparatorBtn.addEventListener('click', () => {
                setTimeout(() => {
                    updateDatalayer('user_comparator_status', getLiveComparatorStatus());
                });
            });
        }
    }

    async function getCommonDatalayerData() {
        const data = retrievePageData();
        const userData = await getUserData();

        // datas injectées via le JS
        // -- user
        data.user_geoloc = getUserLocationTrackingStatus();
        data.user_email = userData.email;
        data.user_logged = userData.logged;
        data.user_basket_status = userData.basket;
        data.user_wishlist_status = userData.wishlist;
        data.user_store = userData.store;
        data.user_comparator_status = getComparatorTrackingStatus();
        data.user_new_customer = getCustomerAccountStatus(userData.logged);
        data.user_profile = userData.profiles;
        data.user_postalcode = getZipcodeUserTracking();
        data.add_basket_area = listenBasketAddArea();
        data.user_eligible_cagnotte = getUserEligibleCagnotte();
        // page
        if (!data.page_referer) {
            data.page_referer = document.referrer;
        }
        getRefTemplateData(data);
        data.env_useragent = navigator.userAgent;
        getProductPosition(data);
        initComparatorBtn();

        return data;
    }

    function fillDataLayer(data) {
        Object.assign(datalayer, data);
        window.ANALYTICS.dataLayerFilled = true;
        document.dispatchEvent(new CustomEvent('datalayer_is_filled.tagcommander', { detail: datalayer }));
    }

    function init() {
        // eslint-disable-next-line no-multi-assign
        datalayer = window.tc_vars = {};
        mainContainer = new TcContainer('main', tagCoUrl);
        retrievePageData = new Parser('data-tagcommander');
    }

    async function loadTagco() {
        init();
        tagCoDatas = await getCommonDatalayerData();
        fillDataLayer(tagCoDatas);
        mainContainer.load();
        loadOmniture();
    }

    await loadTagco();
}
