import micromodalInit from './micromodal_init.js';
import tagCommanderInit from './tagcommander.js';
import kameleoonInit from './kameleoon.js';
import tagCommanderListener from './tagcommander_listener.js';
import initToggler from './toggler.js';
import switchInit from './switch.js';
import addClickCollectInit from './add_click_collect.js';
import initCustomInput from './custom_input.js';
import filterProductsSlider from './product_slider_filter.js';
import toggleDropDown from './eb_filter.js';

function readyFunction() {
    if (!$('body').data('sans-partenaires')) {
        tagCommanderInit();
        kameleoonInit();
        tagCommanderListener();
    }
    micromodalInit();
    initCustomInput();
    filterProductsSlider();
}

function loadFunction() {
    addClickCollectInit();
    initToggler();
    toggleDropDown();
}

function quickLoad() {
    switchInit();
}

quickLoad();
document.addEventListener('DOMContentLoaded', readyFunction);
window.addEventListener('load', loadFunction);
